import { Dropdown } from "react-bootstrap";
import MWSVG from "./svg";
import { useState } from "react";
import { toAbsoluteUrl } from "../../helper/AssetHelpers";


const Header = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      {/* topbar */}
      <div className="topbar px-4 position-fixed top-0 end-0 start-0 bg-white border-bottom d-flex flex-wrap align-items-center">
        <div className="position-relative d-flex w-100 align-items-center ">
          <div
            id="navbarToggler"
            className={
              show
                ? "navbar-toggler cIdeaTribes d-lg-none"
                : "navbar-toggler d-lg-none"
            }
            onClick={() => {
              setShow(!show);
            }}
          >
            <span className="icon-bar top-bar"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </div>
          <div className="d-flex flex-1 align-items-center ">
            <a className="full-logo d-block d-lg-none mx-4" href="/">
              <img
                src={toAbsoluteUrl("/media/logo.png")}
                alt="IdeaTribe"
                title="IdeaTribe"
                height="36"
                width="36"
                loading="eager"
                className="mx-auto "
              />
            </a>
          </div>
          <div className="d-flex flex-wrap align-items-center font-semibold ms-auto">
            <Dropdown className="user-drop">
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <img
                  src={toAbsoluteUrl("/media/profile.png")}
                  alt="John"
                  height="32"
                  width="32"
                  className="rounded-circle"
                />
                <span className="mx-10">John</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <g opacity="0.5">
                    <path
                      d="M10.0005 13.0942L15.0089 8.08583L13.8314 6.90666L10.0005 10.74L6.17052 6.90666L4.99219 8.085L10.0005 13.0942Z"
                      fill="#1F384C"
                    />
                  </g>
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <button className="py-1 px-3 btn btn-link d-flex ">
            <MWSVG id="ic-notification" svgClassName="ic-notification" />
          </button>
        </div>
      </div>

      {/* sidebar */}
      <div
        id="mySidebar"
        className={
          show ? "sidebar bg-white navShow" : "sidebar bg-white navHide"
        }
      >
        <div className="brand-logo ">
          <a className="full-logo " href="/">
            <img
              src={toAbsoluteUrl("/media/logo.png")}
              alt="IdeaTribe"
              title="IdeaTribe"
              height="102"
              width="112"
              loading="eager"
              className="mx-auto w-auto"
            />
          </a>
        </div>
        <div className="px-4">
          <p className="font-20 fw-600 text-gray-700">Demo</p>
          <div className="font-36 fw-600 text-gray-700 ">
            Master Skill Framework
          </div>
        </div>
        <div className="menu d-flex flex-column px-4">
          <ul className="ps-0 list-unstyled">
            <li className="active">
              <a href="/build">
                <MWSVG id="ic-shape-plus" />
                <span className="nav-text">Build</span>
              </a>
            </li>
            <li>
              <a href="/validate">
                <MWSVG id="ic-comment" />
                <span className="nav-text">Validate</span>
              </a>
            </li>
            <li>
              <a href="/grow">
                <MWSVG id="ic-arrow-expand-all" />
                <span className="nav-text">Grow</span>
              </a>
            </li>
            <li>
              <a href="/">
                <MWSVG id="ic-share-variant" />
                <span className="nav-text">Share</span>
              </a>
            </li>
          </ul>
          <a
            href="/"
            className="d-flex text-gray-500 text-decoration-none ps-1"
          >
            <MWSVG id="ic-info-square" svgClassName="mx-2" />
            <span className="ps-1">Help</span>
          </a>
        </div>
      </div>
      <div
        onClick={() => {
          setShow(false);
        }}
        className={show ? "overlay show" : "overlay"}
      ></div>
    </>
  );
};
export default Header;
