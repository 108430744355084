import Header from "../components/common/header";
import MwIcons from "../components/icons";


const MasterLayout = (Component: any) => {
    return (props: any) => {
        return <>
            <main className="main-wrapper body-wrapper">
                    <Header />
                    <Component {...props} />
                    <MwIcons />
            </main>
        </>
    }
}

export default MasterLayout;
