import React from 'react'
import MasterLayout from '../layout/masterlayout';


const Build = (props: any) => {
  return (
    <div> Build</div>
  )
}

export default MasterLayout(Build);