
import React from "react"

function MwIcons() {
  return (
    <svg width={0} height={0} className="d-none">
  <symbol
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 11"
    id="ic-arrow-up"
  >
    <path
      d="M8 0.6875L16 8.6875L14.125 10.5625L8 4.4375L1.875 10.5625L0 8.6875L8 0.6875Z"
      fill="black"
      fillOpacity="0.54"
    />
  </symbol>
  <symbol
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    id="ic-info-square"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.7525 1.49939H12.255C14.7975 1.49939 16.5 3.28439 16.5 5.93939V12.0676C16.5 14.7151 14.7975 16.4994 12.255 16.4994H5.7525C3.21 16.4994 1.5 14.7151 1.5 12.0676V5.93939C1.5 3.28439 3.21 1.49939 5.7525 1.49939ZM8.99256 6.79515C8.64006 6.79515 8.34756 6.5019 8.34756 6.14265C8.34756 5.77515 8.64006 5.48265 9.00756 5.48265C9.36756 5.48265 9.66006 5.77515 9.66006 6.14265C9.66006 6.5019 9.36756 6.79515 8.99256 6.79515ZM9.65244 11.8351C9.65244 12.1951 9.35994 12.4876 8.99244 12.4876C8.63244 12.4876 8.33994 12.1951 8.33994 11.8351V8.52013C8.33994 8.15938 8.63244 7.86013 8.99244 7.86013C9.35994 7.86013 9.65244 8.15938 9.65244 8.52013V11.8351Z"
      fill="#97979B"
    />
  </symbol>
  <symbol
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    id="ic-shape-plus"
  >
    <path
      d="M2 2H11V11H2V2ZM17.5 2C20 2 22 4 22 6.5C22 9 20 11 17.5 11C15 11 13 9 13 6.5C13 4 15 2 17.5 2ZM6.5 14L11 22H2L6.5 14ZM19 17H22V19H19V22H17V19H14V17H17V14H19V17Z"
      fill="currentColor"
    />
  </symbol>
  <symbol
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    id="ic-share-variant"
  >
    <path
      d="M18 16.08C17.24 16.08 16.56 16.38 16.04 16.85L8.91 12.7C8.96 12.47 9 12.24 9 12C9 11.76 8.96 11.53 8.91 11.3L15.96 7.19C16.5 7.69 17.21 8 18 8C18.7956 8 19.5587 7.68393 20.1213 7.12132C20.6839 6.55871 21 5.79565 21 5C21 4.20435 20.6839 3.44129 20.1213 2.87868C19.5587 2.31607 18.7956 2 18 2C17.2044 2 16.4413 2.31607 15.8787 2.87868C15.3161 3.44129 15 4.20435 15 5C15 5.24 15.04 5.47 15.09 5.7L8.04 9.81C7.5 9.31 6.79 9 6 9C5.20435 9 4.44129 9.31607 3.87868 9.87868C3.31607 10.4413 3 11.2044 3 12C3 12.7956 3.31607 13.5587 3.87868 14.1213C4.44129 14.6839 5.20435 15 6 15C6.79 15 7.5 14.69 8.04 14.19L15.16 18.34C15.11 18.55 15.08 18.77 15.08 19C15.08 20.61 16.39 21.91 18 21.91C19.61 21.91 20.92 20.61 20.92 19C20.92 18.2256 20.6124 17.4829 20.0648 16.9352C19.5171 16.3876 18.7744 16.08 18 16.08Z"
      fill="currentColor"
    />
  </symbol>
  <symbol
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    id="ic-star"
  >
    <path
      d="M8 12.8589L12.944 16L11.632 10.08L16 6.09684L10.248 5.58316L8 0L5.752 5.58316L0 6.09684L4.368 10.08L3.056 16L8 12.8589Z"
      fill="#DDC140"
    />
  </symbol>
  <symbol
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    id="ic-tray-arrow-up"
  >
    <path
      d="M2 12H4V17H20V12H22V17C22 18.11 21.11 19 20 19H4C2.9 19 2 18.11 2 17V12ZM12 2L6.46 7.46L7.88 8.88L11 5.75V15H13V5.75L16.13 8.88L17.55 7.45L12 2Z"
      fill="#1D2939"
    />
  </symbol>
  <symbol
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    id="ic-send"
  >
    <path
      d="M16 0C18.1012 0 20.1817 0.413852 22.1229 1.21793C24.0641 2.022 25.828 3.20055 27.3137 4.68629C28.7994 6.17203 29.978 7.93586 30.7821 9.87706C31.5861 11.8183 32 13.8988 32 16C32 20.2435 30.3143 24.3131 27.3137 27.3137C24.3131 30.3143 20.2435 32 16 32C13.8988 32 11.8183 31.5861 9.87706 30.7821C7.93586 29.978 6.17203 28.7994 4.68629 27.3137C1.68571 24.3131 0 20.2435 0 16C0 11.7565 1.68571 7.68687 4.68629 4.68629C7.68687 1.68571 11.7565 0 16 0ZM9.6 9.136V14.48L21.024 16L9.6 17.52V22.864L25.6 16L9.6 9.136Z"
      fill="#1E1E1F"
    />
  </symbol>
  <symbol
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    id="ic-alert-circle"
  >
    <mask id="path-1-inside-1_1609_14305" fill="white">
      <path d="M13 13H11V7H13M13 17H11V15H13M12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z" />
    </mask>
    <path
      d="M13 13H11V7H13M13 17H11V15H13M12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z"
      fill="#DC6803"
    />
    <path
      d="M11 13H10V14H11V13ZM11 7V6H10V7H11ZM11 17H10V18H11V17ZM11 15V14H10V15H11ZM2 12H1H2ZM12 22V23V22ZM13 12H11V14H13V12ZM12 13V7H10V13H12ZM11 8H13V6H11V8ZM13 16H11V18H13V16ZM12 17V15H10V17H12ZM11 16H13V14H11V16ZM12 1C10.5555 1 9.12506 1.28452 7.79048 1.83733L8.55585 3.68508C9.64778 3.23279 10.8181 3 12 3V1ZM7.79048 1.83733C6.4559 2.39013 5.24327 3.20038 4.22183 4.22183L5.63604 5.63604C6.47177 4.80031 7.46392 4.13738 8.55585 3.68508L7.79048 1.83733ZM4.22183 4.22183C2.15893 6.28473 1 9.08262 1 12H3C3 9.61305 3.94821 7.32387 5.63604 5.63604L4.22183 4.22183ZM1 12C1 14.9174 2.15893 17.7153 4.22183 19.7782L5.63604 18.364C3.94821 16.6761 3 14.3869 3 12H1ZM4.22183 19.7782C5.24327 20.7996 6.4559 21.6099 7.79048 22.1627L8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364L4.22183 19.7782ZM7.79048 22.1627C9.12506 22.7155 10.5555 23 12 23V21C10.8181 21 9.64778 20.7672 8.55585 20.3149L7.79048 22.1627ZM12 23C14.9174 23 17.7153 21.8411 19.7782 19.7782L18.364 18.364C16.6761 20.0518 14.3869 21 12 21V23ZM19.7782 19.7782C21.8411 17.7153 23 14.9174 23 12H21C21 14.3869 20.0518 16.6761 18.364 18.364L19.7782 19.7782ZM23 12C23 10.5555 22.7155 9.12506 22.1627 7.79048L20.3149 8.55585C20.7672 9.64778 21 10.8181 21 12H23ZM22.1627 7.79048C21.6099 6.4559 20.7996 5.24327 19.7782 4.22183L18.364 5.63604C19.1997 6.47177 19.8626 7.46392 20.3149 8.55585L22.1627 7.79048ZM19.7782 4.22183C18.7567 3.20038 17.5441 2.39013 16.2095 1.83733L15.4442 3.68508C16.5361 4.13738 17.5282 4.80031 18.364 5.63604L19.7782 4.22183ZM16.2095 1.83733C14.8749 1.28452 13.4445 1 12 1V3C13.1819 3 14.3522 3.23279 15.4442 3.68508L16.2095 1.83733Z"
      fill="#DC6803"
      mask="url(#path-1-inside-1_1609_14305)"
    />
  </symbol>
  <symbol
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    id="ic-arrow-back"
  >
    <g clipPath="url(#clip0_1691_1944)">
      <path
        d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1691_1944">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </symbol>
  <symbol
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    id="ic-arrow-expand-all"
  >
    <path
      d="M9.5 13.09L10.91 14.5L6.41 19H10V21H3V14H5V17.59L9.5 13.09ZM10.91 9.5L9.5 10.91L5 6.41V10H3V3H10V5H6.41L10.91 9.5ZM14.5 13.09L19 17.59V14H21V21H14V19H17.59L13.09 14.5L14.5 13.09ZM13.09 9.5L17.59 5H14V3H21V10H19V6.41L14.5 10.91L13.09 9.5Z"
      fill="currentColor"
    />
  </symbol>
  <symbol
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    id="ic-check"
  >
    <g clipPath="url(#clip0_1912_3555)">
      <path
        d="M8.00065 1.33337C4.32065 1.33337 1.33398 4.32004 1.33398 8.00004C1.33398 11.68 4.32065 14.6667 8.00065 14.6667C11.6807 14.6667 14.6673 11.68 14.6673 8.00004C14.6673 4.32004 11.6807 1.33337 8.00065 1.33337ZM6.66732 11.3334L3.33398 8.00004L4.27398 7.06004L6.66732 9.44671L11.7273 4.38671L12.6673 5.33337L6.66732 11.3334Z"
        fill="#12B76A"
      />
    </g>
    <defs>
      <clipPath id="clip0_1912_3555">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </symbol>
  <symbol
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    id="ic-chevron-down"
  >
    <g opacity="0.5">
      <path
        d="M10.0005 13.0942L15.0089 8.08583L13.8314 6.90666L10.0005 10.74L6.17052 6.90666L4.99219 8.085L10.0005 13.0942Z"
        fill="#3C3C3E"
      />
    </g>
  </symbol>
  <symbol
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    id="ic-comment"
  >
    <path
      d="M20 2H4C2.9 2 2 2.89 2 4V16C2 17.11 2.9 18 4 18H8V21C8 21.55 8.45 22 9 22H9.5C9.75 22 10 21.9 10.2 21.71L13.9 18H20C21.11 18 22 17.11 22 16V4C22 2.9 21.11 2 20 2ZM10.47 14L7 10.5L8.4 9.09L10.47 11.17L15.6 6L17 7.41L10.47 14Z"
      fill="currentColor"
    />
  </symbol>
  <symbol
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 11"
    id="ic-arrow-down"
  >
    <path
      d="M8 10.3125L1.63371e-06 2.3125L1.875 0.437498L8 6.5625L14.125 0.437499L16 2.3125L8 10.3125Z"
      fill="black"
      fillOpacity="0.54"
    />
  </symbol>
  <symbol
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 16 19"
  id="ic-notification"
>
  <g id="notification">
    <path
      id="Notif Icon"
      d="M6.4 19C5.51684 18.9957 4.80073 18.2924 4.792 17.4206H7.992C7.99369 17.6318 7.9529 17.8411 7.872 18.0366C7.66212 18.512 7.23345 18.8587 6.72 18.9684H6.716H6.704H6.6896H6.6824C6.58945 18.9875 6.49492 18.9981 6.4 19ZM12.8 16.631H0V15.0516L1.6 14.2619V9.91871C1.55785 8.80457 1.81275 7.69902 2.3392 6.71262C2.86323 5.79779 3.75896 5.14817 4.8 4.92795V3.20645H8V4.92795C10.0632 5.41281 11.2 7.18484 11.2 9.91871V14.2619L12.8 15.0516V16.631Z"
      fill="#3C3C3E"
    />
    <path
      id="Notif Sign"
      d="M11 8.52258C12.9809 8.52258 14.6 6.93546 14.6 4.96129C14.6 2.98712 12.9809 1.4 11 1.4C9.01915 1.4 7.4 2.98712 7.4 4.96129C7.4 6.93546 9.01915 8.52258 11 8.52258Z"
      fill="#EC5252"
      stroke="white"
      strokeWidth="1.2"
    />
  </g>
</symbol>

</svg>


  )
}

export default MwIcons
