import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Validate from "../pages/validate";
import Build from "../pages/build";
import Grow from "../pages/grow";
import "../assets/css/style.scss";

const Router: any = createBrowserRouter([
  { path: "/", element: <Validate /> },
  { path: "/build", element: <Build /> },
  { path: "/validate", element: <Validate /> },
  { path: "/grow", element: <Grow /> },
]);

export default Router;
