import React from 'react'
import MasterLayout from '../layout/masterlayout';


const Validate = (props: any) => {
  return (
    <div> Validate</div>
  )
}

export default MasterLayout(Validate);