import React from 'react'
import MasterLayout from '../layout/masterlayout';


const Grow = (props: any) => {
  return (
    <div> Grow</div>
  )
}

export default MasterLayout(Grow);