import React from "react"

type Props = {
  id: string
  svgClassName?: string
  width?: number
  height?: number
}

const MWSVG: React.FC<Props> = ({ id, svgClassName = "", height, width }) => {
  return (
    <svg className={`ic ${svgClassName}`} height={height} width={width}>
      <use xlinkHref={`#${id}`}></use>
    </svg>
  )
}

export default MWSVG
